import * as React from "react";
import PrepLogo from "../../../images/logo.png";
import { Link } from "gatsby";
import "./style.scss";
const Header = () => {
  const pages = [
    { path: "/", title: "PrEP Underutilization" },
    { path: "/real-world-prep-use", title: "Real-World PrEP Use" },
  ];
  return (
    <header className="flex flex-col md:flex-row justify-between mt-4">
      <div className="my-3 mx-auto md:w-1/4 md:mr-6">
        <Link to="/">
          <img src={PrepLogo} width="238" />
        </Link>
      </div>
      <nav className="bg-grey-medium mx-auto pt-4 px-4 flex justify-center items-end w-full md:w-3/4">
        {pages.map((page) => (
          <Link to={page.path} className="text-green font-bold" key={page.path}>
            <div className="bg-grey-light text-nav text-center py-4 px-4 md:px-10">
              {page.title}
            </div>
          </Link>
        ))}
      </nav>
    </header>
  );
};
export default Header;
